@mixin baseText {
  //   color: var(--color-text);
}

@mixin primaryFont {
  @include baseText();
}

@mixin secondaryFont {
  @include baseText();
}

:root {
  .ellipsisText {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .cursor {
    cursor: pointer;
  }
  .flexCenter {
    display: flex;
    align-items: center;
    &Justify {
      @extend .flexCenter;
      justify-content: center;
    }
    &Row {
      @extend .flexCenter;
      flex-direction: row;
    }
    &Column {
      @extend .flexCenter;
      flex-direction: column;
    }
    &SpaceBetween {
      @extend .flexCenter;
      justify-content: space-between;
    }
  }
  .hero {
    @include baseText();

    font-size: 40px;
    line-height: 52px;
    align-items: center;

    &Bold {
      @extend .hero;
      font-weight: bold;
    }
  }

  .greetingLarge {
    @include baseText();

    font-size: 36px;
    line-height: 48px;

    &Bold {
      @extend .greetingLarge;
      font-weight: bold;
    }
  }

  .greeting {
    @include baseText();

    font-size: 32px;
    line-height: 48px;

    &Bold {
      @extend .greeting;
      font-weight: bold;
    }
  }

  .pageTitle {
    @include baseText();

    font-size: 28px;
    line-height: 40px;

    &Bold {
      @extend .pageTitle;
      font-weight: bold;
    }
  }

  .panelTitle {
    @include baseText();

    font-size: 24px;
    line-height: 36px;

    &Bold {
      @extend .panelTitle;
      font-weight: bold;
    }
  }

  .headerTitle {
    @include baseText();

    font-size: 19px;
    line-height: 22.27px;

    &Bold {
      @extend .headerTitle;
      font-weight: bold;
    }
  }

  .header {
    @include baseText();

    font-size: 20px;
    line-height: 28px;

    &Bold {
      @extend .header;
      font-weight: bold;
    }
  }

  .smallHeader {
    @include baseText();

    font-size: 18px;
    line-height: 28px;

    &Bold {
      @extend .smallHeader;
      font-weight: bold;
    }
  }

  .overline {
    @include baseText();

    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }

  .bodyText {
    @include baseText();

    font-size: 16px;
    line-height: 24px;

    &Bold {
      @extend .bodyText;
      font-weight: bold;
    }

    &Link {
      @extend .bodyText;
      font-weight: bold;
      text-decoration: underline;
    }

    &Link:hover {
      cursor: pointer;
    }
  }

  .bodyTextSmall {
    @include baseText();

    font-size: 14px;
    line-height: 20px;

    &Bold {
      @extend .bodyTextSmall;
      font-weight: bold;
    }

    &Link {
      @extend .bodyTextSmall;
      font-weight: bold;
      text-decoration: underline;
    }

    &Link:hover {
      cursor: pointer;
    }
  }

  .metadata {
    @include baseText();

    font-size: 12px;
    line-height: 20px;
  }

  .metadataSmall {
    @include baseText();

    font-size: 10px;
    line-height: 16px;
  }

  .label {
    @include baseText();

    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.32px;
    font-weight: bold;

    &:focus,
    &:active,
    &:focus-within,
    &.Mui-focused {
      color: var(--color-text);
    }
  }

  .inputLabel {
    @include baseText();

    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
  }

  .tooltipBody {
    @include baseText();

    font-size: 12px;
    line-height: 16px;
  }
}
